<template>
  <div style="overflow: scroll;">
    <table
      class="table b-table table-fixed"
      :style="resultData.total == 0 ? '' : 'width: 1560px;'"
    >
      <thead>
        <tr>
          <th>User</th>
          <th>Nomor Tagihan</th>
          <th>Total</th>
          <th>Metode Pembayaran</th>
          <th>Status Pembayaran</th>
          <th>Waktu Kedaluwarsa</th>
          <th>Item</th>
          <th />
        </tr>
      </thead>
      <tbody>
        <tr v-if="isLoadingComponent">
          <td
            colspan="7"
            class="text-center"
          >
            <b-spinner
              class="mb-1"
              variant="primary"
            /><br>
            Loading...
          </td>
        </tr>
        <tr
          v-for="item in resultData.data"
          v-else
          :key="item.id"
        >
          <td>
            <a
              class="text-primary"
              @click="goToDetail(item.user.uuid)"
            >
              {{ item.user.name ? item.user.name : '-' }}
            </a>
          </td>
          <td>
            {{ item.invoice_number ? item.invoice_number : '-' }}
          </td>
          <td>
            <b-list-group flush>
              <b-list-group-item v-if="item.pital_pay !== 0">
                Pital Pay: {{ item.pital_pay | formatAmount }}
              </b-list-group-item>
              <b-list-group-item v-if="item.pital_coin !== 0">
                Pital Coin: {{ item.pital_coin | formatAmount }}
              </b-list-group-item>
              <b-list-group-item v-if="item.total !== 0">
                Total: {{ item.total | formatAmount }}
              </b-list-group-item>
            </b-list-group>
          </td>
          <td>
            {{ item.payment_method ? item.payment_method : '-' | humanize }}<br>
            <span
              v-if="item.payment_account"
              class="text-primary"
            >
              {{ item.payment_account.account_name }}<br><small class="text-secondary">{{ item.payment_account.bank_name }} | {{ item.payment_account.account_number }} </small>
            </span>
            <span v-else />
          </td>
          <td>
            <span
              class="badge text-capitalize"
              :class="item.payment_status == 'pending' ? 'badge-light-warning' : item.payment_status == 'failed' ? 'badge-light-danger' : 'badge-light-success'"
            >
              {{ item.payment_status ? item.payment_status : '-' }}
            </span>
          </td>
          <td>
            {{ item.expire_time ? item.expire_time : '-' }}
          </td>
          <td>
            <b-list-group flush>
              <b-list-group-item
                v-for="(invoice, indexInvoice) in item.items"
                :key="indexInvoice"
              >
                {{ invoice.name }}
              </b-list-group-item>
            </b-list-group>
          </td>
          <td>
            <b-dropdown
              v-if="item.payment_status == 'pending'"
              variant="info"
              size="sm"
            >
              <template #button-content>
                Action
              </template>
              <b-dropdown-item
                v-if="checkPermission('approve payment')"
                @click="approveReject(item.uuid, 'approve')"
              >
                <feather-icon
                  icon="CheckCircleIcon"
                  class="mr-75 text-capitalize"
                />
                Approve Pembayaran
              </b-dropdown-item>
              <b-dropdown-item
                v-if="checkPermission('reject payment')"
                @click="approveReject(item.uuid, 'reject')"
              >
                <feather-icon
                  icon="XCircleIcon"
                  class="mr-75 text-capitalize"
                />
                Reject Pembayaran
              </b-dropdown-item>
            </b-dropdown>
          </td>
        </tr>
        <tr v-if="result.total == 0 && !isLoading">
          <td
            colspan="7"
            class="text-center"
          >
            Data is empty.
          </td>
        </tr>
      </tbody>
    </table>

    <div
      v-if="resultData.total > 0"
      class="m-1"
    >
      <div class="row">
        <div class="col mb-1">
          <small>Showing {{ resultData.from }} to {{ resultData.to }} from {{ resultData.total }}</small>
        </div>
        <div class="col">
          <pagination
            :data="resultData"
            :limit="4"
            align="right"
            @pagination-change-page="getData"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  BDropdown,
  BDropdownItem,
  BSpinner,
  BListGroup,
  BListGroupItem,
} from 'bootstrap-vue'
import { checkPermission, successNotification, errorNotification } from '@/auth/utils'

export default {
  components: {
    BDropdown,
    BDropdownItem,
    BSpinner,
    BListGroup,
    BListGroupItem,
  },
  /* eslint-disable vue/require-default-prop */
  props: {
    result: {
      type: Object,
    },
    isLoading: {
      type: Boolean,
    },
    getData: {
      type: Function,
    },
  },
  setup(props) {
    return {
      checkPermission,
      successNotification,
      errorNotification,
    }
  },
  data() {
    return {
      isLoadingComponent: true,
      resultData: {},
    }
  },
  watch: {
    isLoading(value) {
      this.isLoadingComponent = value
    },
    result(value) {
      this.resultData = value
    },
  },
  methods: {
    approveReject(uuid, type) {
      this.$http.post(`admin/payment/${uuid}/${type}`)
        .then(response => {
          this.getData()
          successNotification(this, 'Success', `Payment successfully ${type}`)
        })
        .catch(error => {
          if (error.response.data.meta.messages.length > 0) {
            errorNotification(this, 'Oops!', error.response.data.meta.messages)
          }
        })
    },
    detailKomplain(id) {
      this.$router.push({ name: 'complaint.show', params: { id } })
    },
    goToDetail(id) {
      this.$router.push({ name: 'users.show', params: { id } })
    },
  },
}
</script>

<style lang="scss">

</style>
